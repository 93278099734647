import {
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
  Dispatch,
} from "react";
import { updateSelectedResource } from "./services/updateSelectedResource";
import { Resource, ServiceState } from "app/types/permissions";
import { useRevalidator } from "@remix-run/react";
import { CampaignSummary, Profile } from "app/types/userData";
import { PPCCampaignType } from "../CurrentCampaignContext/types";

export type UserDataValue = {
  accessToken?: string;
  refreshToken?: string;
  idToken?: string;
  email?: string;
  challengeName?: string | null;
  challengeToken?: string | null;
  employeeId?: number | null;
  profile?: Profile;
  selectedResources?: Record<string, Resource | null | undefined>;
  selectedPPCCampaign: PPCCampaignType | null;
  selectedPPLCampaign: Resource | null;
  permissions?: Record<string, ServiceState>;
  campaignSummary?: CampaignSummary | null;
  isLoggedIn: boolean;
  routeAfterLogin: string;
  userId?: string;
};

type UserDataContextType = UserDataValue & {
  setTokens: Dispatch<
    SetStateAction<{
      accessToken: string | undefined;
      refreshToken: string | undefined;
      idToken: string | undefined;
    }>
  >;
  selectResource: (service: "ppc" | "ppl", resourceId: number) => Promise<void>;
  updateProfile: (profile: Profile) => void;
  refreshData: VoidFunction;
};

const UserDataContext = createContext<UserDataContextType>({
  setTokens: () => {},
  selectResource: () => Promise.resolve(),
  updateProfile: () => {},
  refreshData: () => {},
  selectedPPCCampaign: null,
  selectedPPLCampaign: null,
  campaignSummary: null,
  isLoggedIn: false,
  routeAfterLogin: "/",
});

type UserDataProviderProps = React.PropsWithChildren<{
  value: UserDataValue;
}>;

export const UserDataProvider = ({
  value,
  children,
}: UserDataProviderProps) => {
  const { revalidate: refreshData } = useRevalidator();
  const [tokens, setTokens] = useState<{
    accessToken: string | undefined;
    refreshToken: string | undefined;
    idToken: string | undefined;
  }>({
    accessToken: value.accessToken,
    refreshToken: value.refreshToken,
    idToken: value.idToken,
  });

  const selectResource = useCallback(
    async (service: "ppc" | "ppl", resourceId: number) => {
      // TODO: Remix migration - we need a better location to call this request. Where?
      await updateSelectedResource(service, resourceId);
      refreshData();
    },
    [refreshData],
  );

  return (
    <UserDataContext.Provider
      value={{
        ...value,
        ...tokens,
        setTokens,
        selectResource,
        updateProfile: refreshData,
        refreshData,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};
UserDataProvider.displayName = "UserProvider";

export const useUserData = (): UserDataContextType => {
  return useContext(UserDataContext);
};
